import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      id: "",
      // 父页面传来的id
      classifyStatus: "",
      recommendList: [],
      //热门推荐列表
      likeList: [],
      //喜欢列表

      total: 8,
      //总条数
      currentPage: 1,
      //当前页数
      pageSize: 16,
      //每页的条数
      pageSizes: [16],
      classify: [],
      lang: "",
      vip: "",
      loadingValue: false,
      tap: false,
      dialogVisible: false,
      contactList: [{
        name: "Web",
        bnm_name: "Web",
        img: require("@/assets/particulars_icons/order_icons/1.png"),
        num: "1123456"
      }, {
        name: "Skype",
        bnm_name: "Skype",
        img: require("@/assets/particulars_icons/order_icons/2.png"),
        num: "1123456"
      }, {
        name: "Facebook",
        bnm_name: "Facebook",
        img: require("@/assets/particulars_icons/order_icons/3.png"),
        num: "1123456"
      }, {
        name: "Instagram",
        bnm_name: "Instagram",
        img: require("@/assets/particulars_icons/order_icons/4.png"),
        num: "1123456"
      }, {
        name: "Twitter",
        bnm_name: "Twitter",
        img: require("@/assets/particulars_icons/order_icons/5.png"),
        num: "1123456"
      }, {
        name: "Linkedin",
        bnm_name: "Linkedin",
        img: require("@/assets/particulars_icons/order_icons/6.png"),
        num: "1123456"
      }, {
        name: "WhatsApp",
        bnm_name: "WhatsApp",
        img: require("@/assets/particulars_icons/order_icons/7.png"),
        num: "1123456"
      }, {
        name: "经纪人执照号",
        bnm_name: "Nombre de la número de licencia de corredor",
        img: require("@/assets/particulars_icons/order_icons/8.png"),
        num: "1123456"
      }, {
        name: "手机号",
        bnm_name: "Celular",
        img: require("@/assets/particulars_icons/order_icons/9.png"),
        num: "1123456"
      }, {
        name: "邮箱",
        bnm_name: "Email",
        img: require("@/assets/particulars_icons/order_icons/10.png"),
        num: "1123456"
      }, {
        name: "地址",
        bnm_name: "Dirección",
        img: require("@/assets/particulars_icons/order_icons/11.png"),
        num: "1123456"
      }],
      //分页数据
      pageNo: 1,
      pageSize: 10,
      total: 0,
      company: [],
      //经纪人公司信息
      borkerList: [] //经纪人信息列表
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.getBorkerInfo();
    console.log(this.id);
    this.lang = localStorage.getItem("lang");
    console.log(this.$route.params.Classify, "111111");

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", e => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    prev() {
      console.log("上一页");
      this.pageNo--;
      this.getBorkerInfo();
    },
    next() {
      console.log("下一页");
      this.pageNo++;
      this.getBorkerInfo();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getBorkerInfo();
      console.log(`当前页: ${val}`);
    },
    gohome() {
      this.$router.push({
        path: ""
      });
    },
    // 获取经纪人详情
    getBorkerInfo() {
      this.$http.getBorkerInfo({
        user_id: this.id,
        pageNo: this.pageNo
      }).then(res => {
        if (res.code == 1) {
          console.log(res.data, 5555);
          this.company = res.data.company;
          this.borkerList = res.data.data.data;
          this.total = res.data.data.total;
          this.contactList.forEach(item => {
            if (item.name == "Web") {
              item.num = this.company.web;
            } else if (item.name == "Skype") {
              item.num = this.company.skype;
            } else if (item.name == "Facebook") {
              item.num = this.company.facebook;
            } else if (item.name == "Instagram") {
              item.num = this.company.instagram;
            } else if (item.name == "Twitter") {
              item.num = this.company.twitter;
            } else if (item.name == "Linkedin") {
              item.num = this.company.linkedin;
            } else if (item.name == "Skype") {
              item.num = this.company.WhatsApp;
            } else if (item.name == "手机号") {
              item.num = this.company.mobile;
            } else if (item.name == "邮箱") {
              item.num = this.company.email;
            } else if (item.name == "地址") {
              item.num = this.company.address;
            }
          });
        }
      });
    },
    goParticulars(item) {
      console.log(item);
      if (item.url == 0) {
        this.$router.push({
          path: "/details",
          query: {
            id: item.id,
            url: item.url
          }
        });
      } else {
        this.$router.push({
          path: "/particulars",
          query: {
            id: item.id,
            url: item.url
          }
        });
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$http.userInfo().then(res => {
        if (res.code == 1) {
          this.vip = res.data.vip;
        } else if (res.code == -201) {} else {
          this.$message.info(res.msg);
        }
      });
    },
    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
    }
  }
};