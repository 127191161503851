var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "all_container"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "changeClassify"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.gohome
    }
  }, [_vm._v(_vm._s(_vm.$t("other.homePage")))]), _c('div', [_vm._v("-")]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("broker")))]), _c('div', [_vm._v("-")]), _c('div', [_vm._v(_vm._s(_vm.$t("brokerParticulars")))])]), _c('div', {
    staticClass: "main_body"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "order"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/particulars_icons/left.png"),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "photo",
    attrs: {
      "src": _vm.company.head_img,
      "alt": ""
    }
  }), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/particulars_icons/right.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.company.name) + " ")])]), _c('div', {
    staticClass: "company"
  }, [_c('div', {
    staticClass: "name"
  }, [_c('div', {
    staticClass: "company_name"
  }, [_vm._v(" " + _vm._s(_vm.company.company) + " ")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/particulars_icons/111.png"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("businessHours")) + "：" + _vm._s(_vm.company.work_time) + " ")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/particulars_icons/222.png"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("agentType")) + "： " + _vm._s(_vm.lang == "zh" ? _vm.company.type_name : _vm.company.sp_name) + " ")]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/particulars_icons/333.png"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.company.address) + " ")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("contactCompany")) + " ")])]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("aboutCompany")) + " ")]), _c('div', {
    staticClass: "intro"
  }, [_vm._v(" " + _vm._s(_vm.company.content) + " ")])])])]), _c('div', {
    staticClass: "right"
  }, _vm._l(_vm.borkerList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.goParticulars(item);
        }
      }
    }, [_c('div', {
      staticClass: "item_top"
    }, [_c('img', {
      attrs: {
        "src": item.thumb[0],
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item_bottom"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "money"
    }, [_vm._v("$" + _vm._s(item.price))]), _c('div', {
      staticClass: "address"
    }, [_c('div', {
      staticClass: "address_box"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/new_home_icons/address_icon.png"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(item.address) + " ")]), _c('div', {
      staticClass: "address_box"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/particulars_icons/photo.png"),
        "alt": ""
      }
    }), _vm._v(" 1/" + _vm._s(item.thumb.length) + " ")])])])]);
  }), 0)]), _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "total"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.gong")) + " " + _vm._s(_vm.total) + " " + _vm._s(_vm.$t("home.tiao")) + " ")]), _c('div', [_c('el-pagination', {
    attrs: {
      "background": true,
      "layout": "prev, pager, next",
      "total": _vm.total,
      "page-size": _vm.pageSize,
      "current-page": _vm.pageNo
    },
    on: {
      "prev-click": _vm.prev,
      "next-click": _vm.next,
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function ($event) {
        _vm.pageNo = $event;
      },
      "update:current-page": function ($event) {
        _vm.pageNo = $event;
      }
    }
  })], 1)])])]), _c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "title_all"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("contactCompany")))])])]), _c('div', {
    staticClass: "dialog_box"
  }, _vm._l(_vm.contactList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "dialog_box_item"
    }, [_c('img', {
      attrs: {
        "src": item.img,
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(_vm.lang == "zh" ? item.name : item.bnm_name) + " : " + _vm._s(item.num))])]);
  }), 0), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Know")))])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };